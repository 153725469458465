@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap");

html,
body {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 200;
  height: 100%;
  scroll-behavior: smooth !important;
}

//// THEME VARIABLES ///////

:root {
  --light-white-1: #ffffff;
  --light-grey-1: #f4fafb;
  --light-blue-1: #c9e6e9;
  --light-blue-3: #91c6d1;
  --light-blue-4: #8bb6c3;
  --light-blue-5: #4ab4bf;
  --light-blue-6: #3b9dae;
  --dark-blue-1: #257490;
  --dark-blue-2: #1d5568;
  --dark-blue-3: #122835;
  --dark-blue-4: #101927;
  --dark-black-5: #000000;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}

.rc-anchor-logo-large {
  display: hidden !important;
}

input,
label,
button {
  font-weight: 100;
}

label {
  font-weight: 200 !important;
}

button,
.btn {
  font-size: 14px;
}

.btn-200 {
  width: 200px;
}

.hint {
  text-align: center;
  font-weight: 100;
  font-size: 11px;
  z-index: 1;
  margin: auto;
}

.hint a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  color: #363636;
}

h2 {
  font-weight: 200 !important;
}

.gold-line {
  width: 100%;
  background-color: #ae9562;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer {
  z-index: 1000 !important;
}

.form-control {
  font-size: 14px;
  font-weight: 200 !important;
  min-height: 40px;
  background-color: #fafafa !important;
  border-radius: 99px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  cursor: not-allowed;
}

.form-control input {
  font-weight: 300 !important;
}

select {
  font-weight: 200 !important;
  cursor: pointer;
}

.error-mgs {
  color: red;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
  font-size: 13px;
}

.modal {
  font-weight: 200;
}

::placeholder {
  color: #bababa !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

// Icon Padding
.fa {
  padding: 5px;
}

// Badges
.badge-primary {
  background-image: linear-gradient(#1d5568, #257490) !important;
}

.badge-secondary {
  background-image: linear-gradient(#6c757d, #4f5050) !important;
}

.badge-warning {
  background-image: linear-gradient(#ffc107, #f0b503) !important;
}

.badge-danger {
  background-image: linear-gradient(#dc3545, #c22736) !important;
}

// Buttons
.btn-nb {
  border-radius: 0px;
}

.btn-secondary {
  color: #fff;
  background-image: linear-gradient(#4f616e, #6c757d) !important;
  border-color: #4c626b !important;
  transition: 0.2s;
  border-radius: 99px;
}

.btn-secondary:hover {
  background-image: linear-gradient(#6c757d, #4f616e) !important;
  border-color: #4c626b !important;
}

.btn-primary {
  color: #fff;
  background-image: linear-gradient(#1d5568, #257490) !important;
  border-color: #204974 !important;
  transition: 0.2s;
  border-radius: 99px;
}

.btn-primary:hover {
  color: #fff;
  background-image: linear-gradient(#257490, #1d5568) !important;
  border-color: #204974 !important;
  transition: 0.2s;
}

.card-item {
  border-radius: 20px !important;
}

.no-transactions {
  border-radius: 20px !important;
}

.pagination-controls {
  text-decoration: none !important;
}

.btn-danger {
  border-radius: 99px;
}

.btn-warning {
  border-radius: 99px;
}

.btn-success {
  border-radius: 99px;
}

.mat-button {
  border-radius: 99px !important;
}

.btn-check:checked + .btn-outline-primary {
  background-image: linear-gradient(#1d5568, #257490) !important;
  color: #fff;
  border-color: #204974;
}

.btn-outline-primary:hover {
  background-image: linear-gradient(#1d5568, #257490) !important;
  color: #fff;
  border-color: #204974;
}

.btn-outline-light {
  border-radius: 99px;
}

.btn-primary:hover {
  background-color: transparent !important;
  border-color: #1d5568 !important;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.form-group {
  margin-bottom: 1rem !important;
}

#phone {
  border-radius: 99px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.modal-title {
  font-weight: 400;
  font-size: 18px;
}

.modal-body {
  font-size: 13px;
}

.modal-b-text {
  font-weight: 700;
  font-size: 12px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1d5568;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1d5568;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.modal-footer button {
  min-width: 150px;
}

input:hover:disabled {
  cursor: not-allowed;
}

input[type="checkbox"] {
  cursor: pointer;
}

// Table scrollbar
.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
  width: 12px;
}

.table-responsive::-webkit-scrollbar:horizontal {
  height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

// Tables
.user-table thead tr th {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.user-table tfoot tr th {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.user-table tbody {
  font-size: 12px;
  font-weight: 400;
}

.user-table-primary thead tr {
  background-image: linear-gradient(#1d5568, #257490);
  color: #ffffff;
  text-align: left;
}

.user-table-primary tfoot tr {
  background-image: linear-gradient(#1d5568, #257490);
  color: #ffffff;
  text-align: left;
}

.user-table-secondary thead tr {
  background-image: linear-gradient(#6c757d, #4f5050);
  color: #ffffff;
  text-align: left;
}

.user-table-secondary tfoot tr {
  background-image: linear-gradient(#6c757d, #4f5050);
  color: #ffffff;
  text-align: left;
}

// Charts scrollbar
.advanced-pie-legend .legend-items-container .legend-items::-webkit-scrollbar {
  -webkit-appearance: none;
}

.advanced-pie-legend .legend-items-container .legend-items:vertical {
  width: 12px;
}

.advanced-pie-legend .legend-items-container .legend-items:horizontal {
  height: 12px;
}

.advanced-pie-legend
  .legend-items-container
  .legend-items::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.advanced-pie-legend
  .legend-items-container
  .legend-items::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

// Chart Legends
.chart-legend .legend-labels {
  overflow-x: auto !important;
}

.chart-legend .legend-labels::-webkit-scrollbar {
  -webkit-appearance: none;
}

.chart-legend .legend-labels:vertical {
  width: 15px;
}

.chart-legend .legend-labels:horizontal {
  height: 15px;
}

.chart-legend .legend-labels::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.chart-legend .legend-labels::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.chart-legend .legend-wrap {
  width: 100% !important;
}

.chart-legend .legend-labels {
  background: #fff !important;
}

.chart-legend .legend-label {
  color: #1b1b1b !important;
}

.box-shadow {
  box-shadow: 0px 4px 12px 1px #e0e0e0;
}

.box-shadow-light {
  box-shadow: 1px 1px 10px 3px #f1f1f1;
}

// Tooltip
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  cursor: pointer;
  z-index: 1;
  height: 24px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #3d3d3d;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  font-weight: 100;
  box-shadow: 4px 4px 13px 1px #000000;
}

.tooltip-left {
  left: -170px;
  top: 25px;
}

.tooltip-right {
  right: -170px;
  top: 25px;
}

.tooltip-top {
  right: -140px;
  top: -65px;
}

.fa-info-circle {
  color: #545454;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

// Cell
:host ::ng-deep .iti {
  width: 100% !important;
}

::ng-deep ngx-intl-tel-input .iti {
  width: 100% !important;
}

::ng-deep ngx-intl-tel-input {
  overflow: hidden !important;
}

// Forms
.form-control-label {
  font-size: 13px;
  margin-left: 12px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.nav-link {
  color: #1d5568;
}

// Pagination
.ngx-pagination .current {
  min-width: 30px;
  background-image: linear-gradient(#6c757d, #4f5050) !important;
  border-radius: 10px !important;
  text-align: center;
  text-decoration: none !important;
}

.ngx-pagination .current:hover {
  cursor: pointer;
  background-image: linear-gradient(#4f5050, #6c757d) !important;
}

.ngx-pagination a {
  text-align: center;
  min-width: 30px;
  border-radius: 10px !important;
  text-decoration: none !important;
}

.ngx-pagination a:hover {
  min-width: 30px;
  border-radius: 10px !important;
  text-decoration: none !important;
}

// Stepper
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background: linear-gradient(#1d5568, #257490) !important;
}

.mat-step:last-child .mat-vertical-content {
  padding: 0px 0px 0px 0px;
  margin-right: 36px;
}

.mat-mdc-button:not(:disabled) {
  color: #fff !important;
}

// Snackbar
.mat-snack-bar-container {
  font-weight: 400 !important;
}

.mat-snack-bar-button-color {
  color: #204974 !important;
}

.mat-simple-snackbar-action button {
  font-weight: 500 !important;
}

.mat-mdc-button .mat-mdc-button-touch-target {
  color: #204974 !important;
}

.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #204974 !important;
}

// Round Slider Portfolio
.rs-tooltip.rs-edit {
  border: 1px solid #c7c7c7;
}

// Media screen
@media only screen and (max-width: 501px) {
  .modal-footer {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 400px) {
  .modal-footer button {
    min-width: 120px;
  }
  .tooltip .tooltiptext {
    width: 200px;
  }
  .tooltip-left {
    left: -200px;
  }

  .tooltip-right {
    right: -200px;
  }

  .tooltip-top {
    right: -115px;
    top: -65px;
  }
}

// SCSS animations
[data-animation] {
  transition-property: opacity, transform;
  transition-duration: 1.3s;
  animation-duration: 1.3s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-timeline: view();
}

// =================== Fade animations ===================
[data-animation="fade-in"] {
  animation-name: fadeIn;
}
[data-animation="fade-out"] {
  animation-name: fadeOut;
}
[data-animation="fade-up"] {
  animation-name: fadeUp;
}
[data-animation="fade-down"] {
  animation-name: fadeDown;
}
[data-animation="fade-left"] {
  animation-name: fadeLeft;
}
[data-animation="fade-right"] {
  animation-name: fadeRight;
}
[data-animation="fade-up-left"] {
  animation-name: fadeUpLeft;
}
[data-animation="fade-up-right"] {
  animation-name: fadeUpRight;
}
[data-animation="fade-down-left"] {
  animation-name: fadeDownLeft;
}
[data-animation="fade-down-right"] {
  animation-name: fadeDownRight;
}

// animation keyframes
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeUp {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
    -moz-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fadeDown {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    -moz-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fadeRight {
  from {
    -webkit-transform: translate3d(-100px, 0, 0);
    -moz-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fadeLeft {
  from {
    -webkit-transform: translate3d(100px, 0, 0);
    -moz-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fadeUpLeft {
  from {
    -webkit-transform: translate3d(100px, 100px, 0);
    -moz-transform: translate3d(100px, 100px, 0);
    transform: translate3d(100px, 100px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fadeUpRight {
  from {
    -webkit-transform: translate3d(-100px, 100px, 0);
    -moz-transform: translate3d(-100px, 100px, 0);
    transform: translate3d(-100px, 100px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fadeDownLeft {
  from {
    -webkit-transform: translate3d(100px, -100px, 0);
    -moz-transform: translate3d(100px, -100px, 0);
    transform: translate3d(100px, -100px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fadeDownRight {
  from {
    -webkit-transform: translate3d(-100px, -100px, 0);
    -moz-transform: translate3d(-100px, -100px, 0);
    transform: translate3d(-100px, -100px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

// =================== Flip animations ===================

[data-animation="flip-"] {
  transform: perspective(2500px) rotate(0);
  backface-visibility: hidden;
  transition-property: transform;
}
[data-animation="flip-left"] {
  animation-name: flipLeft;
}
[data-animation="flip-right"] {
  animation-name: flipRight;
}
[data-animation="flip-up"] {
  animation-name: flipUp;
}
[data-animation="flip-down"] {
  animation-name: flipDown;
}

// animation keyframes
@keyframes flipLeft {
  from {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
    -moz-transform: perspective(2500px) rotateY(-100deg);
    transform: perspective(2500px) rotateY(-100deg);
  }
  to {
    -webkit-transform: perspective(2500px) rotateY(0);
    -moz-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
}
@keyframes flipRight {
  from {
    -webkit-transform: perspective(2500px) rotateY(100deg);
    -moz-transform: perspective(2500px) rotateY(100deg);
    transform: perspective(2500px) rotateY(100deg);
  }
  to {
    -webkit-transform: perspective(2500px) rotateY(0);
    -moz-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
}
@keyframes flipUp {
  from {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
    -moz-transform: perspective(2500px) rotateX(-100deg);
    transform: perspective(2500px) rotateX(-100deg);
  }
  to {
    -webkit-transform: perspective(2500px) rotateX(0);
    -moz-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
}
@keyframes flipDown {
  from {
    -webkit-transform: perspective(2500px) rotateX(100deg);
    -moz-transform: perspective(2500px) rotateX(100deg);
    transform: perspective(2500px) rotateX(100deg);
  }
  to {
    -webkit-transform: perspective(2500px) rotateX(0);
    -moz-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
}

// =================== Zoom animations ===================

[data-animation^="zoom"] {
  transition-property: opacity, transform;
}
[data-animation="zoom-in"] {
  animation-name: zoomIn;
}
[data-animation="zoom-in-up"] {
  animation-name: zoomInUp;
}
[data-animation="zoom-in-down"] {
  animation-name: zoomInDown;
}
[data-animation="zoom-in-left"] {
  animation-name: zoomInLeft;
}
[data-animation="zoom-in-right"] {
  animation-name: zoomInRight;
}
[data-animation="zoom-out"] {
  animation-name: zoomOut;
}
[data-animation="zoom-out-up"] {
  animation-name: zoomOutUp;
}
[data-animation="zoom-out-down"] {
  animation-name: zoomOutDown;
}
[data-animation="zoom-out-left"] {
  animation-name: zoomOutLeft;
}
[data-animation="zoom-out-right"] {
  animation-name: zoomOutRight;
}

// animation keyframes
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
    -moz-transform: translate3d(0, 100px, 0) scale(0.6);
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
    -moz-transform: translate3d(0, -100px, 0) scale(0.6);
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
    -moz-transform: translate3d(100px, 0, 0) scale(0.6);
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
    -moz-transform: translate3d(-100px, 0, 0) scale(0.6);
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomOut {
  from {
    opacity: 0;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomOutUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
    -moz-transform: translate3d(0, 100px, 0) scale(1.2);
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomOutDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
    -moz-transform: translate3d(0, -100px, 0) scale(1.2);
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomOutLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
    -moz-transform: translate3d(100px, 0, 0) scale(1.2);
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
@keyframes zoomOutRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
    -moz-transform: translate3d(-100px, 0, 0) scale(1.2);
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    -moz-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
}
